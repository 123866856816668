.service-container {
  background-color: white;
  color: #007f5f;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-header {
  text-align: center;
  margin-bottom: 20px;
}

.service-header h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.service-header p {
  font-size: 1.2em;
}

.service-description {
  text-align: center;
  margin-bottom: 20px;
}

.service-description p {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.signup-button,
.learn-more-button {
  background-color: #007f5f;
  color: white;
  border: none;
  padding: 12px 30px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  border-radius: 25px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.signup-button:hover,
.learn-more-button:hover {
  background-color: #005f45;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.service-details {
  margin-bottom: 20px;
}

.service-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-card {
  background-color: #f9f9f9;
  border: 1px solid #007f5f;
  border-radius: 8px;
  padding: 20px;
  margin: 15px;
  width: 280px;
  text-align: center;
  transition: transform 0.3s;
}

.service-card:hover {
  transform: scale(1.05);
}

.service-card h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.service-card p {
  font-size: 1em;
}

.pricing-plans {
  text-align: center;
  margin-top: 40px;
}

.pricing-plans h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.pricing-plans p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.pricing-card {
  background-color: #f9f9f9;
  border: 1px solid #007f5f;
  border-radius: 8px;
  padding: 20px;
  margin: 15px;
  width: 320px;
  text-align: center;
  transition: transform 0.3s;
}

.pricing-card:hover {
  transform: scale(1.05);
}

.pricing-card h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.pricing-card p {
  font-size: 1.2em;
  margin-bottom: 15px;
}

.pricing-card ul {
  list-style-type: none;
  padding: 0;
}

.pricing-card ul li {
  margin: 8px 0;
}
