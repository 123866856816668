.footer {
  background-color: #2b9348; /* Blue background matching the navbar */
  color: #fff;
  text-align: center;
  padding: 20px 0;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content h4 {
  margin: 0;
  color: #ffdd00; /* Gold color matching the navbar hover state */
  font-size: 1.25rem;
}

.footer-content p {
  margin: 8px 0;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  transition: color 0.3s ease;
}

.footer-content p:hover {
  color: #ffdd00; /* Gold color on hover */
}

.footer-icons {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.footer-icons a {
  color: #fff;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.footer-icons a:hover {
  color: #ffdd00;
}

@media (max-width: 768px) {
  .footer-content {
    padding: 10px;
  }
}
