/* General Styles */
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  background: linear-gradient(135deg, #2b9348, #007f5f);
  background-size: 400% 400%;
  color: #fff;
  box-shadow: none;
  animation: gradientAnimation 15s ease infinite;
  position: sticky;
  top: 0;
  z-index: 1000;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.logo {
  height: 50px;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-item {
  text-decoration: none;
  color: #fff;
  padding: 0.75em 1.5em;
  font-size: 1.2em;
  transition: color 0.3s ease;
}

.nav-item:hover,
.nav-item:focus {
  color: #fff;
}

.nav-item.nav-cta {
  background: linear-gradient(135deg, #ffdd00, #cca900);
  color: #333;
  padding: 0.75em 1.5em;
  border-radius: 30px;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  font-weight: bold;
  white-space: nowrap;
}

.nav-item.nav-cta:hover,
.nav-item.nav-cta:focus {
  background: linear-gradient(135deg, #cca900, #ffdd00);
  color: #fff;
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.nav-item.nav-cta:active {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.dropdown {
  text-decoration: none;
  position: relative;
}

.dropdown-toggle {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #2b9348;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 1.2em;
}

.dropdown-content a:hover {
  background-color: #007f5f;
}

.show {
  display: block !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Menu icon for mobile */
.menu-icon {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.menu-icon-line {
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.menu-icon-line.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-icon-line.open:nth-child(2) {
  opacity: 0;
}

.menu-icon-line.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.dropdown-icon {
  margin-left: 8px;
}

@media (max-width: 1024px) {
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #2b9348;
    z-index: 1;
    padding: 1em 0;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-item {
    width: 100%;
    text-align: center;
    padding: 1em 0;
    font-size: 1.4em;
  }

  .nav-item.nav-cta {
    margin-top: 0.5em;
    width: 90%;
    align-self: center;
  }

  .dropdown-content {
    position: static;
    background-color: transparent;
    box-shadow: none;
  }

  .dropdown-content a {
    padding-left: 2em;
  }

  .menu-icon {
    display: flex;
  }
}
