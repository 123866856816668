.getting-started-container {
  background-color: #f0f4f8;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 2400px;
  margin: 0 auto;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.getting-started-header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 32px;
  color: #333;
}

.getting-started-intro {
  text-align: center;
  margin-bottom: 40px;
  font-size: 18px;
  color: #666;
}

.getting-started-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
}

.getting-started-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 1 1 250px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.getting-started-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.getting-started-icon {
  font-size: 50px;
  color: #2b9348;
  margin-bottom: 10px;
}

.getting-started-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 5px;
}

.getting-started-list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
}

.getting-started-list li {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.list-icon {
  font-size: 24px;
  color: #2b9348;
  margin-right: 10px;
}

.contact-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  font-size: 18px;
  color: #333;
}

.contact-icon {
  font-size: 24px;
  margin-right: 10px;
  color: #2b9348;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.cta-button {
  background-color: #004494;
  color: #fff;
  padding: 0.75em 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 200px;
  text-align: center;
}

.cta-button:hover,
.cta-button:focus {
  background-color: #2b9348;
  transform: scale(1.05);
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .getting-started-section {
    flex-direction: column;
  }

  .getting-started-list li {
    flex-direction: column;
    align-items: flex-start;
  }

  .cta-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .cta-button {
    width: 100%;
    max-width: 300px;
  }
}
