.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 6em 2em;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  color: #fff; /* White text to stand out against the hero background */
  overflow: hidden;
  position: relative;
}

.hero-overlay {
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Slightly increase the transparency for a more elegant look */
  padding: 2em;
  border-radius: 25px; /* More rounded corners */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
}

.hero-section h1 {
  font-size: 3em; /* Large font size for the main heading */
  margin-bottom: 0.5em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Adjust the shadow for better readability */
}

.hero-section p {
  font-size: 1.5em;
  margin-bottom: 1em;
  max-width: 600px; /* Max width to control the line length of the text */
  opacity: 1; /* Full opacity for better visibility */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Adjust the shadow for better readability */
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 1em;
}

.cta-button {
  background-color: #004494;
  color: #fff;
  padding: 0.75em 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cta-button:hover,
.cta-button:focus {
  background-color: #3070f0;
  transform: scale(1.05);
}

/* Responsive adjustments for hero content */
@media (max-width: 768px) {
  .hero-section {
    padding: 3em 1em;
  }

  .hero-overlay {
    padding: 1.5em;
    border-radius: 15px; /* Adjusted for smaller screens */
  }

  .hero-section h1 {
    font-size: 2em;
  }

  .hero-section p {
    font-size: 1em;
  }

  .cta-buttons {
    flex-direction: column;
    gap: 0.5em;
  }

  .cta-button {
    width: 100%;
    max-width: 300px;
  }
}
