/* General Layout */
.carrier-setup {
  max-width: 960px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.form-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.form-description {
  color: #666;
  font-size: 14px;
  margin-bottom: 20px;
}

/* Form Fields and Labels */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-field,
.form-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-field:focus,
.form-select:focus {
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 86, 179, 0.25);
  outline: none;
}

/* Errors */
.error {
  color: red;
  font-size: 12px;
  margin-top: -5px;
  margin-bottom: 10px;
}

/* Button Styling */
.submit-button {
  background-color: #2b9348;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: block; /* Makes it easier to center */
  width: 100%;
  box-sizing: border-box; /* Ensures padding doesn't affect width */
}

.submit-button:hover {
  background-color: #2b9348;
}

/* Responsive Handling */
@media (max-width: 768px) {
  .carrier-setup {
    padding: 10px;
  }
  .form-title,
  .form-description {
    text-align: center;
  }
  .submit-button {
    padding: 10px 20px;
  }
}

/* Additional Styling for Radio Buttons and Grouping */
.radio-buttons label {
  display: inline-block;
  margin-right: 20px;
  font-weight: normal;
  cursor: pointer;
}

.radio-buttons input[type="radio"] {
  margin-right: 5px;
}

.address-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.required {
  color: red;
}

.address-group .form-group {
  display: inline-block;
  margin-right: 20px;
}

@media (max-width: 600px) {
  .address-group {
    grid-template-columns: 1fr;
  }
}

/* Existing styles remain unchanged */

.checkbox-group label {
  margin-bottom: 5px; /* Adds space between checkboxes */
  cursor: pointer; /* Indicates an interactive item */
  display: block; /* Ensures each label occupies its own line */
}

.file-upload .file-msg {
  margin-left: 10px;
  font-size: 16px;
}

.file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

/**/

.checkbox-group {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  height: 150px; /* Fixed height for scrollability */
  overflow-y: auto; /* Enables vertical scrolling */
  display: flex;
  flex-direction: column; /* Ensures items are stacked vertically */
}

.file-upload .file-drop-area {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 2px dashed #ccc;
  text-align: center;
  margin-top: 10px;
}

.file-upload .file-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-top: 5px;
  background-color: #f4f4f4;
  border-radius: 4px;
}

.file-upload .file-info button {
  padding: 5px 10px;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.file-upload .fake-btn {
  background-color: #666;
  color: #fff;
  border: none;
  padding: 8px 20px;
  border-radius: 4px;
  cursor: pointer;
}
