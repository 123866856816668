.services-offered {
  padding: 60px 20px;
  background-color: #f8f9fa;
  text-align: center;
}

.services-offered h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #2b9348;
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.service-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 300px;
  text-align: left;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.service-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-card h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.service-card p {
  font-size: 1em;
  margin-bottom: 20px;
  color: #555;
}

.learn-more {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 1em;
  border-radius: 20px;
  background-color: #2b9348;
  color: #fff;
  font-size: 1em;
  transition: background-color 0.3s ease, transform 0.2s ease,
    box-shadow 0.3s ease;
  text-decoration: none;
}

.learn-more svg {
  margin-left: 0.5em;
}

.learn-more:hover {
  background-color: #2b9348;
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cta-button {
  padding: 1em 2em;
  border: none;
  background: linear-gradient(135deg, #ffdd00, #cca900);
  color: #333;
  font-size: 1em;
  border-radius: 30px;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  margin-right: 1em;
  cursor: pointer;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.cta-button:last-child {
  margin-right: 0;
}

.cta-button:hover {
  background: linear-gradient(135deg, #cca900, #ffdd00);
  color: #fff;
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cta-button:active {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .services-grid {
    flex-direction: column;
    align-items: center;
  }

  .cta-button {
    padding: 0.8em 1.6em;
    font-size: 0.9em;
  }
}
