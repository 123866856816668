.cta-button {
  padding: 1em 2em;
  border: none;
  background: linear-gradient(
    135deg,
    #ffdd00,
    #cca900
  ); /* Gradient background for CTA buttons */
  color: #333; /* Dark text for contrast on the buttons */
  font-size: 1em;
  border-radius: 30px; /* More rounded corners for the buttons */
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease; /* Added transition for smoother hover effect */
  margin-right: 1em; /* Spacing between buttons */
  cursor: pointer; /* Change cursor to pointer to indicate interactivity */
  font-weight: bold; /* Bold font for better readability */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Slight text shadow for depth */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow to the buttons */
}

.cta-button:last-child {
  margin-right: 0;
}

.cta-button:hover {
  background: linear-gradient(
    135deg,
    #cca900,
    #ffdd00
  ); /* Inverse gradient on hover for a dynamic effect */
  color: #fff;
  transform: scale(
    1.1
  ); /* Scale up the button slightly on hover for a more interactive effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add a more pronounced shadow on hover for depth */
}

.cta-button:active {
  transform: scale(
    1.05
  ); /* Slightly reduce the scale on active for a pressed effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Reduce the shadow on active for depth */
}

/* Responsive Handling */
@media (max-width: 768px) {
  .cta-button {
    padding: 0.8em 1.6em; /* Adjust padding for smaller screens */
    font-size: 0.9em; /* Adjust font size for smaller screens */
  }
}
