.service-container {
  background-color: white;
  color: #007f5f;
}

.service-header {
  text-align: center;
  margin-bottom: 20px;
}

.service-header h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.service-header p {
  font-size: 1.2em;
}

.service-description {
  text-align: center;
  margin-bottom: 20px;
}

.service-description p {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.signup-button {
  background-color: #007f5f;
  color: white;
  border: none;
  padding: 12px 30px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  border-radius: 25px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.signup-button:hover {
  background-color: #005f45;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.service-details {
  text-align: center;
  margin-bottom: 40px;
}

.service-details h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.service-details p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.process-flow {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.process-step {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 80%;
  padding: 20px;
  border: 1px solid #007f5f;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  justify-content: center;
}

.step-number {
  font-size: 1.5em;
  font-weight: bold;
  background-color: #007f5f;
  color: white;
  border-radius: 50%;
  padding: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.step-description {
  text-align: center;
}

.step-description h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.step-description p {
  font-size: 1em;
}

.benefits-section {
  text-align: center;
  margin-top: 40px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
}

.benefits-section h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.benefits-section ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.benefits-section ul li {
  font-size: 1.2em;
  margin: 10px 20px;
  display: flex;
  align-items: center;
}

.benefits-section ul li span {
  color: #007f5f;
  font-size: 1.5em;
  margin-right: 10px;
}
