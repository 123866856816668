.choose-us-container {
  text-align: center;
  padding: 40px 20px;
  background-color: #f9f9f9; /* Light background for a brighter theme */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 8px;
  overflow: hidden;
  max-width: 2000px;
  margin: 0 auto;
}

.choose-us-container h1 {
  color: #333; /* Dark color for the title */
  margin-bottom: 30px;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  border: 1px solid transparent;
  background-color: #fff;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  font-size: 0.9em;
  font-weight: bold;
  text-transform: uppercase;
}

.tab-button:hover,
.tab-button.active {
  color: #fff;
  background-color: #2b9348; /* Brand color */
  border-color: #2b9348;
}

.tab-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transition: all 0.5s ease;
}

.tab-image {
  flex: 1;
  max-width: 50%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 5px;
}

.content {
  flex: 1;
  padding: 20px;
  min-width: 500px; /* Ensure content has a minimum width */
}

.content p {
  color: #555; /* Subdued text color for the content */
  background-color: #fff; /* White background for content area */
  border-radius: 5px;
  padding: 20px;
  transition: all 0.3s ease;
  min-height: 100px; /* Ensure content area has a consistent height */
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.cta-button {
  background-color: #2b9348;
  color: #fff;
  padding: 0.75em 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 200px;
  text-align: center;
}

.cta-button:hover,
.cta-button:focus {
  background-color: #2b9348;
  transform: scale(1.05);
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .tab-content {
    gap: 10px;
  }

  .tab-image {
    max-width: 60%;
  }

  .content {
    min-width: 250px;
  }
}

@media (max-width: 768px) {
  .choose-us-container {
    padding: 20px 10px;
  }

  .tabs {
    flex-direction: column;
    gap: 5px;
  }

  .tab-button {
    padding: 10px;
  }

  .tab-content {
    flex-direction: column;
    padding: 10px;
  }

  .tab-image {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .content {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .tab-button {
    font-size: 0.8em;
    padding: 8px 16px;
  }

  .cta-button {
    width: 100%;
    max-width: 200px;
  }
}
