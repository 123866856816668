body {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
}

.about-section {
  padding: 0px 0px;
  color: #333;
}

/* Hero Section */
.hero-section {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  margin-bottom: 60px;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(70%);
}

.hero-text {
  position: relative;
  z-index: 1;
}

.hero-text h1 {
  font-size: 42px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #fff;
}

.hero-text p {
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
  color: #f8f9fa;
  background-color: rgba(
    0,
    0,
    0,
    0.4
  ); /* Adding a semi-transparent background to the text */
  padding: 10px; /* Adding some padding around the text */
  border-radius: 5px; /* Optional: rounded corners for the text background */
}

/* Content Sections */
.about-content {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-content h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #2b9348;
  font-weight: bold;
}

.about-content p {
  font-size: 16px;
  margin-bottom: 40px;
  color: #666;
  line-height: 1.8;
}

/* Team Section */
.team-section .team-members {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.team-member {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  padding: 20px;
  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.team-member:hover {
  transform: scale(1.05);
}

.team-image {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-bottom: 10px;
}

.team-member h3 {
  font-size: 20px;
  margin-bottom: 5px;
  color: #333;
}

.team-member p {
  font-size: 16px;
  color: #666;
}

/* Contact Section */
.about-contact {
  text-align: center;
  margin-top: 60px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-button {
  background-color: #2b9348;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  margin-top: 20px;
}

.contact-button:hover {
  background-color: #2b9348;
  transform: scale(1.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .about-content {
    padding: 10px;
  }

  .hero-image {
    height: 250px;
  }

  .hero-text h1 {
    font-size: 32px;
  }

  .hero-text p {
    font-size: 16px;
  }

  .about-content h2 {
    font-size: 26px;
  }

  .about-content p {
    font-size: 14px;
  }

  .team-members {
    flex-direction: column;
    align-items: center;
  }

  .team-member {
    margin-bottom: 20px;
  }
}
