.delivery-setup {
  max-width: 960px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.form-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.form-description {
  color: #666;
  font-size: 14px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-field,
.form-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-field:focus,
.form-select:focus {
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 86, 179, 0.25);
  outline: none;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: -5px;
  margin-bottom: 10px;
}

.submit-button {
  background-color: #2b9348;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: block; /* Makes it easier to center */
  width: 100%;
  box-sizing: border-box; /* Ensures padding doesn't affect width */
}

.submit-button:hover {
  background-color: #2b9348;
}

/* Responsive Handling */
@media (max-width: 768px) {
  .delivery-setup {
    padding: 10px;
  }
  .form-title,
  .form-description {
    text-align: center;
  }
  .submit-button {
    padding: 10px 20px;
  }
}

.file-upload .file-msg {
  margin-left: 10px;
  font-size: 16px;
}

.file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.required {
  color: red;
}
