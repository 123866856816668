.service-container {
  background-color: white;
  color: #007f5f;
  padding: 20px;
}

.service-header {
  text-align: center;
  margin-bottom: 20px;
}

.service-header h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.service-header p {
  font-size: 1.2em;
}

.service-description {
  text-align: center;
  margin-bottom: 20px;
}

.service-description p {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.signup-button {
  background-color: #007f5f;
  color: white;
  border: none;
  padding: 12px 30px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  border-radius: 25px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.signup-button:hover {
  background-color: #005f45;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 40px 0;
}

.timeline::before {
  content: "";
  position: absolute;
  width: 4px;
  background-color: #007f5f;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.timeline-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  padding: 20px 0;
}

.timeline-item:nth-child(odd) {
  flex-direction: row-reverse;
}

.timeline-item::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #007f5f;
  border: 4px solid white;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.timeline-content {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #007f5f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 40%;
  text-align: center;
  position: relative;
  z-index: 2;
}

.timeline-content h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.timeline-content p {
  font-size: 1em;
}

.benefits-section {
  text-align: center;
  margin-top: 40px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
}

.benefits-section h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.benefits-section ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.benefits-section ul li {
  font-size: 1.2em;
  margin: 10px 20px;
  display: flex;
  align-items: center;
}

.benefits-section ul li span {
  color: #007f5f;
  font-size: 1.5em;
  margin-right: 10px;
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .timeline-item {
    flex-direction: column !important;
  }

  .timeline-item:nth-child(odd) .timeline-content,
  .timeline-item:nth-child(even) .timeline-content {
    width: 80%;
  }

  .timeline-item::before {
    left: 50% !important;
  }
}
