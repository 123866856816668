body {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
}

.services-section {
  padding: 40px 20px;
  background-color: #f8f9fa;
  color: #333;
}

.services-header {
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
  color: #444;
}

.services-intro {
  font-size: 18px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 40px;
  color: #666;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.service-item {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  transition: transform 0.2s ease-in-out;
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Inherit color to ensure consistency */
}

.service-item:hover {
  transform: scale(1.05);
}

.service-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #28a745;
}

.service-description {
  font-size: 16px;
  color: #555;
}

.service-item a {
  text-decoration: none; /* Ensure no underline on link elements */
  color: inherit; /* Ensure links inherit the color of their parent */
}

@media (max-width: 768px) {
  .services-list {
    flex-direction: column;
    align-items: center;
  }
}
