.testimonial-section {
  background: #fff;
  padding: 40px 20px;
  text-align: center;
}

.testimonial-header {
  margin-bottom: 40px;
}

.testimonial-subtitle {
  color: #2b9348;
  font-weight: bold;
  letter-spacing: 1px;
}

.testimonial-title {
  font-size: 32px;
  margin: 10px 0;
}

.testimonial-description {
  color: #777;
  max-width: 600px;
  margin: 0 auto;
}

.testimonial-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.testimonial-card {
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  padding: 20px;
  text-align: left;
  flex: 1;
  min-width: 280px;
}

.testimonial-stars {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.star-icon {
  color: #f4c150;
  font-size: 20px;
  margin: 0 2px;
}

.testimonial-name {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px;
}

.testimonial-title {
  color: #999;
  font-size: 14px;
  margin-bottom: 10px;
}

.testimonial-quote {
  font-size: 24px;
  color: #2b9348;
  margin: 0;
}

.testimonial-text {
  color: #777;
  font-size: 14px;
  line-height: 1.5;
}

.cta-button {
  background-color: #004494;
  color: #fff;
  padding: 0.75em 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 200px;
  text-align: center;
}

.cta-button:hover,
.cta-button:focus {
  background-color: #3070f0;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .testimonial-cards {
    flex-direction: column;
    align-items: center;
  }
}
