.services-section {
  background-color: #f0f4f8; /* Light grey background for a modern look */
  padding: 50px;
  text-align: center;
}

.services-section h2 {
  color: #333; /* Dark grey text for contrast */
  margin-bottom: 40px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.service-card {
  background-color: #ffffff; /* White background for cards */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px); /* Slight lift effect on hover */
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.service-card h3 {
  color: #333; /* Dark grey text for the title */
  margin-bottom: 15px;
}

.service-card p {
  color: #666; /* Lighter text for description */
  font-size: 0.9em;
}
.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 1em;
  padding: 20px;
}

.cta-button {
  background-color: #004494;
  color: #fff;
  padding: 0.75em 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 220px; /* Fixed width for buttons */
  text-align: center;
}

.cta-button:hover,
.cta-button:focus {
  background-color: #3070f0;
  transform: scale(1.05);
}

/* Responsive adjustments for services section */
@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr; /* Stack cards on smaller screens */
  }

  .cta-buttons {
    flex-direction: column;
    gap: 0.5em;
    align-items: center; /* Center buttons in column layout */
  }

  .cta-button {
    width: 100%;
    max-width: 300px;
  }
}
