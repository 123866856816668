.key-metrics {
  text-align: center;
  padding: 50px; /* Keeping padding consistent with services-section */
  background-color: #f0f4f8; /* Same light grey background for a modern look */
}

.key-metrics .header {
  margin-bottom: 40px; /* Matching the margin bottom with services-section h2 */
}

.metrics {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, 1fr)
  ); /* Switch to grid for consistency */
  gap: 20px; /* Maintain consistent spacing */
}

.metric {
  background-color: #ffffff; /* White background for cards */
  border-radius: 10px; /* Rounded corners for the cards */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s ease; /* Smooth transition for interactive elements */
}

.metric:hover {
  transform: translateY(-10px); /* Slight lift effect on hover */
}

.metric .icon {
  font-size: 40px; /* Adjusting size to match services-section */
  color: #2b9348; /* Brand color for icons */
  margin-bottom: 20px;
}

.metric h3 {
  font-size: 2em;
  color: #333; /* Dark grey text for the title */
  margin-bottom: 15px;
}

.metric p {
  font-size: 0.9em; /* Adjusting to match the description text size in services-section */
  font-weight: normal; /* Removed bold for consistency */
  color: #666; /* Lighter text for description */
}

.metric small {
  display: block;
  margin-top: 1em;
  color: #666;
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .metrics {
    grid-template-columns: 1fr; /* Stack cards on smaller screens */
  }

  .metric {
    margin-bottom: 20px; /* Consistent with services-section's gap */
  }
}
